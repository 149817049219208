var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"01ec20ee84a588daab39cd7d6ea835e1e556e273"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { sendErrorToSlack } from 'components/legacy/services/slack';

Sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

	beforeSend(event) {
		const errorDetails = {
			message: event.exception?.values[0]?.value || 'No message',
			stackTrace:
				event.exception?.values[0]?.stacktrace?.frames?.map((frame) => frame.filename || '').join('\n') || null,
			userAgent: event.request?.headers?.['User-Agent'] || null,
			fromUrl: event.request?.url || null,
			capturedBySentry: true
		};

		sendErrorToSlack(errorDetails)
			.then(() => console.log('Message envoyé à Slack'))
			.catch((err) => console.error('Erreur to slack:', err));

		return event; // Permet à l'événement de continuer à être traité par Sentry
	},

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0.1,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,

	replaysOnErrorSampleRate: 0.1,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// You can remove this option if you're not planning to use the Sentry Session Replay feature:
	integrations: [
		new Sentry.BrowserTracing(),
		new Sentry.Replay({
			// Additional Replay configuration goes in here, for example:
			maskAllText: true,
			blockAllMedia: true
		})
	]
});
