export async function sendErrorToSlack(body: {
	message: string;
	stackTrace: string;
	fromUrl: string;
	userAgent: string;
	capturedBySentry: boolean;
}): Promise<void> {
	try {
		const response = await fetch(`${process.env.NEXT_PUBLIC_API}/api/slack`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		});

		if (!response.ok) {
			throw new Error(`Erreur lors de l'envoi à Slack : ${response.statusText}`);
		}

		console.log('Message envoyé à Slack avec succès.');
	} catch (err) {
		console.error("Erreur lors de l'envoi à Slack :", err);
	}
}
