// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export interface User {
	id: string;
	firstname: string;
	lastname: string;
	email: string;
	picture: string;
}

interface IResponse {
	data: any;
	errors: any;
}

interface CopilotsResponse extends IResponse {
	data: {
		copilots: User[];
	};
}

export const copilotApi = createApi({
	reducerPath: 'copilotApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	tagTypes: [],
	endpoints: (build) => ({
		getCopilots: build.query<CopilotsResponse, void>({
			query: () => '/api/copilots'
		}),
		getOrganizations: build.query({
			query: (copilotId) => `/copilots/${copilotId}/organizations`
		})
	})
});

export const { useGetCopilotsQuery, useLazyGetOrganizationsQuery } = copilotApi;
