// Externals
import { combineReducers } from '@reduxjs/toolkit';

// Slices

// Api
import { accountApi } from '../services/accounts';
import { adminApi } from '../services/admins';
import { copilotApi } from '../services/copilots';
import { answersApi } from '../services/answers';
import { auditLevelGridsApi } from '../services/auditLevelGrids';
import { auditsApi } from '../services/audits';
import { audit360Api } from '../services/Audit360';
import { billingApi } from '../services/billing';
import { blogPrivateApi, blogPublicApi } from '../services/blog';
import { choicesApi } from '../services/choices';
import { codesApi } from '../services/codes';
import { connectorsApi } from '../services/connectors';
import { contactApi, contactPrivateApi } from '../services/contact';
import { cyberPlanApi } from '../services/cyberplan';
import { districtLevelGridsApi } from '../services/districtLevelGrids';
import { districtsApi } from '../services/districts';
import { emailLeakDetectorApi } from '../services/EmailLeakDetector';
import { reportsApi } from '../services/emailReport';
import { languageApi } from '../services/languages';
import { logsApi } from '../services/Logs';
import { organizationPermissionsApi } from '../services/organization-permissions';
import { organizationRoleApi } from '../services/organization-roles';
import { organizationMemberApi } from '../services/organizationMember';
import { organizationsApi } from '../services/organizations';
import { questionsApi } from '../services/questions';
import { questsApi } from '../services/quests';
import { socialDataApi } from '../services/socialData';
import { storageUserApi } from '../services/storage-user';
import { taskItemApi } from '../services/taskitems';
import { tipsApi } from '../services/tips';
import { translationApi } from '../services/traductions';
import { storageApi } from '../services/storages';
import { userPrivateApi, userPublicApi } from '../services/userProfiles';

import { reducer as userReducer } from '../slice/user';
import { historicalDataApi } from '../services/historical-data';

const reducer = combineReducers({
	user: userReducer,
	[accountApi.reducerPath]: accountApi.reducer,
	[adminApi.reducerPath]: adminApi.reducer,
	[copilotApi.reducerPath]: copilotApi.reducer,
	[answersApi.reducerPath]: answersApi.reducer,
	[auditLevelGridsApi.reducerPath]: auditLevelGridsApi.reducer,
	[auditsApi.reducerPath]: auditsApi.reducer,
	[audit360Api.reducerPath]: audit360Api.reducer,
	[billingApi.reducerPath]: billingApi.reducer,
	[blogPublicApi.reducerPath]: blogPublicApi.reducer,
	[blogPrivateApi.reducerPath]: blogPrivateApi.reducer,
	[choicesApi.reducerPath]: choicesApi.reducer,
	[codesApi.reducerPath]: codesApi.reducer,
	[connectorsApi.reducerPath]: connectorsApi.reducer,
	[contactApi.reducerPath]: contactApi.reducer,
	[cyberPlanApi.reducerPath]: cyberPlanApi.reducer,
	[districtLevelGridsApi.reducerPath]: districtLevelGridsApi.reducer,
	[districtsApi.reducerPath]: districtsApi.reducer,
	[reportsApi.reducerPath]: reportsApi.reducer,
	[languageApi.reducerPath]: languageApi.reducer,
	[logsApi.reducerPath]: logsApi.reducer,
	[organizationPermissionsApi.reducerPath]: organizationPermissionsApi.reducer,
	[organizationRoleApi.reducerPath]: organizationRoleApi.reducer,
	[organizationMemberApi.reducerPath]: organizationMemberApi.reducer,
	[organizationsApi.reducerPath]: organizationsApi.reducer,
	[emailLeakDetectorApi.reducerPath]: emailLeakDetectorApi.reducer,
	[questionsApi.reducerPath]: questionsApi.reducer,
	[questsApi.reducerPath]: questsApi.reducer,
	[socialDataApi.reducerPath]: socialDataApi.reducer,
	[storageUserApi.reducerPath]: storageUserApi.reducer,
	[taskItemApi.reducerPath]: taskItemApi.reducer,
	[tipsApi.reducerPath]: tipsApi.reducer,
	[translationApi.reducerPath]: translationApi.reducer,
	[storageApi.reducerPath]: storageApi.reducer,
	[userPublicApi.reducerPath]: userPublicApi.reducer,
	[userPrivateApi.reducerPath]: userPrivateApi.reducer,
	[historicalDataApi.reducerPath]: historicalDataApi.reducer,
	[contactPrivateApi.reducerPath]: contactPrivateApi.reducer
});

export default reducer;
