// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const logsApi = createApi({
	reducerPath: 'logsApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	endpoints: (build) => ({
		addLog: build.mutation({
			query: (body: { clientOrigin: string; interval?: number }) => ({
				url: `/api/log`,
				method: 'POST',
				body
			})
		})
	})
});

export const { useAddLogMutation } = logsApi;
